<template>
    <div class="p-5 text-center">
        <div class="h4 mb-4">
            {{ $l("platon.table_excel_exporting", "Жадвал Excel хужжат кўринишига ўтказилмоқда") }}
        </div>
        <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
    </div>
</template>

<script>
import * as XLSX from "xlsx/dist/xlsx.mini.min"

export default {
    name: "ExportTableDialog",

    props: {
        table: {
            required: true
        }
    },

    mounted() {
        this.export()
    },

    methods: {
        export() {
            const workbook = XLSX.utils.table_to_book(this.table)

            XLSX.writeFileXLSX(workbook, "Report.xlsx", { cellStyles: true })

            setTimeout(() => {
                this.$emit("close")
            }, 1000)
        }
    }
}
</script>
